import React from 'react';
import Default from '../../../common/Themes/Default/Theme';
import { omit, without } from 'lodash';
import { UX2, constants } from '@wsb/guac-widget-core';
import {
  sectionHeadingAlignment,
  sectionHeadingColor,
  sectionHeadingSize,
  sectionHeadingHR
} from '../../../common/utils/themeOverrides';
import * as modernIcons from '../../../common/IconPacks/modernThinRound';
import * as socialIconPack from '../../../common/IconPacks/SocialIcons/defaultSocialIconPack';
import { Balance } from '../../../common/loaders';
import { sectionHrTypes, WIDGETS_WITH_CIRCULAR_IMAGES } from '../../../common/constants';
import { mergeTypographyOverrides } from '../../../common/utils/typography';
import themeConfig from '../themeConfig';

const { FULL_UNDERLINE } = sectionHrTypes;

// The Minimal theme left aligns content heading and text.  Add a widget layout to this array for center alignment:
const widgetsWithCenteredText = [
  'about1',
  'content2',
  'content4',
  'introduction4',
  'payment1',
  'payment2'
];

const { buttons } = constants;
const typographyShared1 = {
  style: {
    font: 'primary',
    color: 'highContrast',
    fontSize: 'xxlarge',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    textTransform: 'none'
  }
};

class Theme11 extends Default {
  static config = themeConfig;

  constructor() {
    super();
    this.mappedValues = {
      ...this.mappedValues,
      typographyOverrides: {
        LogoAlpha: {
          ...typographyShared1
        },
        HeadingAlpha: {
          ...typographyShared1
        },
        HeadingBeta: {
          style: {
            font: 'primary',
            fontSize: 'large',
            color: 'highContrast',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        HeadingGamma: {
          style: {
            font: 'alternate',
            fontSize: 'xlarge',
            color: 'highContrast',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        HeadingEpsilon: props => ({
          ...mergeTypographyOverrides.call(this, 'DetailsGamma', props)
        }),
        DetailsGamma: {
          style: {
            font: 'alternate',
            color: 'lowContrast',
            fontSize: 'small',
            fontWeight: 'normal',
            letterSpacing: '0.143em',
            textTransform: 'uppercase'
          }
        },
        NavAlpha: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'small',
            fontWeight: 'normal',
            letterSpacing: '0.143em',
            textTransform: 'uppercase'
          }
        },
        SubNavAlpha: {
          style: {
            font: 'alternate',
            color: 'section',
            fontSize: 'small',
            fontWeight: 'normal',
            letterSpacing: 'none',
            textTransform: 'none'
          }
        },
        ButtonAlpha: {
          style: {
            font: 'alternate',
            fontWeight: 'bold',
            letterSpacing: '0.143em',
            textTransform: 'uppercase'
          }
        }
      }
    };
  }

  static get displayName() {
    return 'Theme11';
  }

  static getMutatorDefaultProps(widgetType, widgetPreset) {
    const defaultProps = super.getMutatorDefaultProps(widgetType, widgetPreset);
    const enableCircularImage =
      WIDGETS_WITH_CIRCULAR_IMAGES[widgetPreset] || defaultProps.enableCircularImage;

    return widgetType === 'HEADER'
      ? {
        ...defaultProps,
        useCropBackground: true,
        useAddress: true,
        useBigLogo: true,
        phoneOnSecondaryPage: true,
        headerTreatmentsConfig: {},
        showAddressDefault: true
      }
      : {
        ...defaultProps,
        enableCircularImage
      };
  }

  static getWidgetDefaultProps(widgetType, widgetPreset) {
    return {
      ...super.getWidgetDefaultProps(widgetType, widgetPreset),
      ...(!widgetsWithCenteredText.includes(widgetPreset) && { alignmentOption: 'left' })
    };
  }

  static excludedProps = without(Default.excludedProps, 'address');

  Section({ children, skipDivider, ...props }) {
    const style = {
      paddingVertical: 'xlarge',
      ['@xs-only']: {
        paddingVertical: 'large'
      }
    };

    let updatedProps = this.merge({ style, children }, props);

    if (this.base.widgetType === 'FOOTER' && !skipDivider) {
      updatedProps = this.merge(
        {
          style,
          children: (
            <React.Fragment>
              <UX2.Element.Divider style={{ paddingVertical: 'medium' }} />
              { children }
            </React.Fragment>
          )
        },
        props
      );
    }

    return super.Section(updatedProps);
  }

  Hero(props) {
    const newStyle = omit(props.style, ['> :nth-child(n)', ' > :last-child']); // remove spacingVertical styles from Minimal
    return super.Hero(Object.assign(props, { style: newStyle }));
  }

  Tagline(props) {
    return super.Tagline(
      this.merge(
        {
          style: {
            marginTop: 'xsmall',
            width: '100%'
          },
          typography: 'HeadingEpsilon'
        },
        props
      )
    );
  }

  HeroText({ alignmentOption, ...props }) {
    return super.SubTagline(
      this.merge(
        {
          style: {
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            maxWidth: '100%',
            lineHeight: '1.25',
            marginBottom: alignmentOption === 'center' ? 'medium' : 'small'
          },
          typography: 'BodyAlpha',
          featured: false
        },
        props
      )
    );
  }

  CardBannerHeading(props) {
    return this.HeadingMajor(props);
  }

  ContentHeading(props) {
    const { widgetPreset } = this.base;

    if (widgetPreset === 'payment2') {
      return this.HeadingMajor(props);
    }
    return super.ContentHeading(props);
  }

  ContentBasic(props) {
    const overrides =
      widgetsWithCenteredText.indexOf(this.base.widgetPreset) === -1 ? { alignment: 'left' } : {};
    return super.ContentBasic(this.merge(overrides, props));
  }

  ContentCard(props) {
    const overrides =
      widgetsWithCenteredText.indexOf(this.base.widgetPreset) === -1 ? { alignment: 'left' } : {};
    const styles = this.base.widgetPreset === 'about1' ? { style: { alignItems: 'center' } } : {};
    return super.ContentCard(this.merge(overrides, styles, props));
  }

  Icon(props) {
    return super.Icon(
      this.merge(
        {
          iconPack: { ...modernIcons, ...socialIconPack }
        },
        props
      )
    );
  }

  Loader(props) {
    return Balance.apply(this, [props]);
  }

  Button(props) {
    const { fill = this.getButtonDefaults().fill } = props;
    const animation =
      fill !== buttons.fills.OPEN
        ? {
          style: {
            transitionProperty: 'background-color',
            transitionDuration: 'medium',
            transitionTimingFunction: 'ease-in-out'
          }
        }
        : {};

    return super.Button(this.merge(animation, props));
  }

  SectionHeading(props) {
    const base = this.base;
    const overrides = this.merge(
      {},
      sectionHeadingAlignment(base),
      sectionHeadingColor(base),
      sectionHeadingSize(base),
      sectionHeadingHR(base)
    );
    return super.SectionHeading(
      this.merge(
        {
          style: {
            '@xs-only': {
              marginBottom: 'medium'
            }
          },
          sectionHeadingHR: FULL_UNDERLINE
        },
        overrides,
        props
      )
    );
  }

  SectionSplitHeading(props) {
    return this.SectionHeading(
      this.merge(
        {
          style: {
            textAlign: 'left'
          }
        },
        props
      )
    );
  }

  Divider(props) {
    const category = this.base.category;
    const footerDividerStyles =
      this.base.widgetType === 'FOOTER' && (category === 'primary' || category === 'accent')
        ? { visibility: 'hidden' }
        : {};
    return super.Divider(this.merge(props, { style: footerDividerStyles }));
  }

  Pipe(props) {
    return super.Pipe(
      this.merge(
        {
          style: {
            opacity: 0.4
          }
        },
        props
      )
    );
  }

  Input(props) {
    return super.Input(
      this.merge(
        {
          typography: 'BodyAlpha',
          style: {
            // Dials Based
            borderColor: 'input',
            // Theme Based
            borderWidth: 'xsmall',
            borderRadius: 'none',
            paddingVertical: 'small',
            paddingHorizontal: 'small',
            // Static
            borderStyle: 'solid'
          }
        },
        props
      )
    );
  }

  InputFloatLabelInput(props) {
    return super.InputFloatLabelInput(
      this.merge(
        {
          style: {
            paddingBottom: 'xsmall'
          }
        },
        props
      )
    );
  }

  InputFloatLabelLabel(props) {
    return super.InputFloatLabelLabel(
      this.merge(
        {
          style: {
            left: '16px'
          }
        },
        props
      )
    );
  }

  InputSelect(props) {
    return super.InputSelect(
      this.merge(
        {
          typography: 'BodyAlpha',
          style: {
            // Theme Based
            borderWidth: 'xsmall',
            borderRadius: 'medium',
            // Static
            borderStyle: 'solid'
          }
        },
        props
      )
    );
  }

  Intro(props) {
    return super.Intro(this.merge({ alignment: 'left' }, props));
  }

  InputSelectElement(props) {
    return super.InputSelectElement(
      this.merge(
        {
          style: {
            paddingVertical: 'small',
            paddingHorizontal: 'small'
          }
        },
        props
      )
    );
  }

  HeadingMajor(props) {
    return super.HeadingMajor(
      this.merge(
        {
          typography: 'HeadingAlpha'
        },
        props
      )
    );
  }

  HeadingMinor(props) {
    return super.HeadingMinor(
      this.merge(
        {
          typography: 'HeadingDelta'
        },
        props
      )
    );
  }

  Phone(props) {
    const style =
      this.base.category === 'accent'
        ? {
          '@xs-only': { color: 'neutral' },
          '@sm-only': { color: 'neutral' }
        }
        : {};
    return super.Phone(
      this.merge(
        {
          typography: 'BodyAlpha',
          featured: false,
          style
        },
        props
      )
    );
  }

  FeaturedHeading(props) {
    return super.FeaturedHeading(
      this.merge(
        {
          typography: 'HeadingDelta'
        },
        props
      )
    );
  }

  NavVerticalHeading(props) {
    return super.NavVerticalHeading(
      this.merge(
        {
          typography: 'HeadingDelta'
        },
        props
      )
    );
  }

  ContentBigHeading(props) {
    return super.ContentBigHeading(
      this.merge(
        {
          typography: 'HeadingGamma'
        },
        props
      )
    );
  }

  ContentBigText(props) {
    return super.ContentBigText(
      this.merge(
        {
          typography: 'BodyAlpha'
        },
        props
      )
    );
  }

  NavMenuLink(props) {
    return super.NavMenuLink(
      this.merge(
        {
          typography: 'NavAlpha'
        },
        props
      )
    );
  }

  ContactBarPhone(props) {
    return super.ContactBarPhone(
      this.merge(
        {
          style: {
            '@xs-only': {
              fontSize: 'small'
            }
          }
        },
        props
      )
    );
  }

  ContactBarAddress(props) {
    return super.ContactBarAddress(
      this.merge(
        {
          style: {
            '@xs-only': {
              fontSize: 'small'
            }
          }
        },
        props
      )
    );
  }

  ContactBarPipe(props) {
    return super.ContactBarPipe(
      this.merge(
        {
          style: {
            '@xs-only': {
              fontSize: 'small'
            }
          }
        },
        props
      )
    );
  }

  PromoBanner(props) {
    return super.PromoBanner(
      this.merge(
        {
          style: {
            paddingVertical: 'xsmall'
          }
        },
        props
      )
    );
  }

  PromoBannerText(props) {
    return super.PromoBannerText(
      this.merge(
        {
          style: {
            '@xs-only': {
              fontSize: 'small'
            }
          }
        },
        props
      )
    );
  }

  Image(props) {
    return super.Image(this.merge({ mobileGutterWidth: 24 }, props));
  }

  EmbedVideo({ isVerticalVideo, ...props }) {
    if (isVerticalVideo) {
      return super.EmbedVideo(this.merge({ style: { maxHeight: '680px' } }, props));
    }
    return super.EmbedVideo(props);
  }

  WrapperInsetEmbedVideo({ isVerticalVideo, ...props }) {
    if (isVerticalVideo) {
      return super.WrapperInsetEmbedVideo(this.merge({ style: { maxHeight: '680px' } }, props));
    }
    return super.WrapperInsetEmbedVideo(props);
  }
}

export default Theme11;
